.ow_events_premium_mtr {
  margin-top: 1rem;
}

.ow_events_premium_lang {
  width: 30%;
}

.ow_events_premium_info {
  margin-left: -5rem;
}

.ow_events_premium_block {
  margin-top: 1rem;
  padding-left: 1.25rem;
}

.ow_events_premium_card {
  width: 100%;
}

.ow_events_premium_coi {
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--busy--color);
  float: right;
}

.ow_events_premium_removeIcon {
  font-size: 1.1rem;
  cursor: pointer;
  color: var(--declined--color);
  float: right;
  margin-right: 1rem;
  margin-top: 0.2rem;
}

.ow_events_premium_error {
  color: var(--declined--color);
  font-size: 80%;
  margin-top: 0.25rem;
}

.ow_events_premium_linkBlock {
  overflow-y: auto;
  overflow-x: hidden;
}

.ow_events_premium_left {
  padding-left: 0rem;
}

.ow_events_premium_displayCard {
  width: 50%;
  height: 100%;
  background: #e9ecef;
  display: flex;
  align-items: center;
}

.ow_events_premium_displayIcon {
  font-size: 2rem;
  opacity: 80%;
}

.ow_events_premium_upload {
  width: 104px;
  height: 104px;
}

.ow_events_premium_uploaButton {
  width: 104px;
  height: 104px !important;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
  text-align: center;
  border-radius: 4px;
  vertical-align: top;
}

.ow_events_premium_attachBlock {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ow_events_premium_fileIcon {
  font-size: 16px;
  color: var(--primary--color);
  margin-right: 0.5rem;
}

.ow_events_premium_rer {
  margin-top: 2rem;
}

.ow_events_premium_customerError {
  color: var(--declined--color);
  font-size: 80%;
  margin-bottom: 0rem;
}

.ow_events_premium_customer {
  font-size: 1.1rem;
  cursor: pointer;
  color: var(--primary--color);
  float: right;
  margin-right: 1rem;
  margin-top: 0.2rem;
}

.ow_events_premium_emailFilter {
  width: 96%;
}

.ow_events_premium_configuration {
  margin-left: 2rem;
}

.ow_events_premium_table {
  margin-bottom: 0rem;
}

.ow_events_premium_timeError {
  color: var(--declined--color);
}

.ow_events_premium_tableColumn {
  width: 45%;
  border: none !important;
}

.ow_events_premium_tableTrash {
  vertical-align: middle;
  border: none !important;
}

.ow_events_premium_openinghourTable {
  overflow-x: scroll !important;
}

.ow_events_premium_Modal {
  max-width: 1000px !important;
}

.ow_events_premium_infoIcon {
  color: var(--primary--color);
}